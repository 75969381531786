import { Textarea } from "@/components/ui/textarea";
import { usePostReply } from "src/state/react-query/useQuestions";
import {
	useFullNameParticipant,
	useUserInfoQuery,
} from "src/state/react-query/useUserInfoQuery";
import { Button } from "@/components/ui/button";
import { IoSend } from "react-icons/io5";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { useLingui } from "@lingui/react";
import { msg, t, Trans } from "@lingui/macro";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form";
import {
	useAnonSwitch,
	useOpenFullNameDialog,
} from "src/state/zustand/userNameDialog";
import { twMerge } from "tailwind-merge";

const replyMaxLength = 2000;

type CreateReplyProps = {
	parentId: string;
	roomId: string;
};

export function CreateReply({ parentId, roomId }: CreateReplyProps) {
	const { _ } = useLingui();
	const userInfo = useUserInfoQuery();
	const { setFullNameDialogIsOpen } = useOpenFullNameDialog();
	const { setIsAnon, isAnon } = useAnonSwitch();
	const userId = userInfo.data?.user?.id;
	const { data } = useFullNameParticipant({
		userId,
	});
	const postReply = usePostReply();
	const hasName = data?.fullName !== null && data?.fullName !== "Anonymous";

	const formSchema = z.object({
		reply: z
			.string()
			.min(3, t`Reply must at least be 3 characters long.`)
			.max(replyMaxLength, t`Reply can not be longer than 2000 characters.`),
		postAnonymous: z.boolean().default(true),
	});

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			reply: "",
			postAnonymous: true,
		},
	});

	const isReplyLengthError = form.formState.errors.reply != null;

	async function handlePostReply(values: z.infer<typeof formSchema>) {
		if (isReplyLengthError) {
			return;
		}
		await postReply.mutateAsync({
			reply: values.reply,
			parent_id: parentId,
			roomId,
			postAnonymous: isAnon,
		});
		form.reset();
		return;
	}

	return (
		<div className="w-full ">
			<Form {...form}>
				<form
					onSubmit={form.handleSubmit(handlePostReply)}
					className="space-y-3"
				>
					{/* Reply */}
					<FormField
						control={form.control}
						name="reply"
						render={({ field }) => (
							<FormItem>
								<FormControl>
									<Textarea
										placeholder={_(msg`Your reply here...`)}
										{...field}
										onChange={field.onChange}
									/>
								</FormControl>
								<p
									className={twMerge(
										"text-xs mt-1 w-fit ml-auto",
										isReplyLengthError ? "  text-red-500" : "text-gray-200",
									)}
								>
									{field.value.length}/{replyMaxLength}
								</p>
							</FormItem>
						)}
					/>

					{/* Post anonymous */}
					<div className="flex items-end justify-start float-left">
						<FormField
							control={form.control}
							name="postAnonymous"
							render={() => (
								<FormItem className="flex flex-col justify-start items-start">
									<Label
										className={`text-gray-500 ${isAnon ? "font-bold" : ""}`}
									>
										{isAnon ? (
											<Trans>Anonymous</Trans>
										) : (
											<Trans>Not anonymous</Trans>
										)}
									</Label>
									<FormControl>
										<Switch
											checked={isAnon}
											onCheckedChange={() => hasName && setIsAnon(!isAnon)}
											onClick={() => {
												if (isAnon && !hasName && userId) {
													setFullNameDialogIsOpen(true);
												}
												return;
											}}
										/>
									</FormControl>
								</FormItem>
							)}
						/>
					</div>

					<div className="flex justify-end">
						<Button size="icon" type="submit">
							<IoSend />
						</Button>
					</div>
				</form>
			</Form>
		</div>
	);
}
