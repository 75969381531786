import { buttonVariants } from "@/components/ui/button";
import { Dialog, DialogTrigger } from "@/components/ui/dialog";
import {
	TooltipContent,
	TooltipTrigger,
	Tooltip,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { BiArrowBack } from "react-icons/bi";
import { IoMdShare } from "react-icons/io";
import { ShareRoomDialog } from "./ShareRoom";
import { backgroundColor } from "src/tailwind/tailwindConstants";
import LiveIndicator from "./LiveIndicator";
import type { ChannelState, RoomState } from "../useSubscribeToRoomChannel";
import { useNavigate } from "@tanstack/react-router";
import { indexRoute } from "src/routes/rootRoute";
import { addSpacingToRoomNumber } from "src/pages/homePage/components/GoToRoomCard";
import { Badge } from "@/components/ui/badge";
import { Trans } from "@lingui/macro";

type RoomHeaderProps = {
	roomState: RoomState;
	channelSubscribeState: ChannelState;
	userCount: number;
};

export const RoomHeader = ({
	channelSubscribeState,
	roomState,
	userCount,
}: RoomHeaderProps) => {
	const singularOrPluralUser =
		userCount > 1 ? <Trans>users</Trans> : <Trans>user</Trans>;
	const navigate = useNavigate();
	return (
		<div
			className={`flex flex-col justify-center drop-shadow-md ${backgroundColor} `}
		>
			<h1 className="text-xl text-center text-white font-sans font-bold">
				{roomState.room_name}
			</h1>
			<h1 className="text-xl text-center text-white font-sans mx-5">
				{addSpacingToRoomNumber(roomState.room_number)}
			</h1>

			<div className="flex items-center justify-center space-x-5 md:space-x-10">
				<div className="">
					<Tooltip>
						<TooltipTrigger
							className={`${cn(buttonVariants({ variant: "outline" }))}`}
							onClick={() => {
								navigate({ to: indexRoute.to });
							}}
						>
							<BiArrowBack className="hover:text-gray-800" />
							<Trans>Back</Trans>
						</TooltipTrigger>
						<TooltipContent>
							<p className="text-xs">
								<Trans>Go back</Trans>
							</p>
						</TooltipContent>
					</Tooltip>
				</div>
				<div className="my-3 col-span-2">
					<Dialog>
						<DialogTrigger
							className={`${cn(buttonVariants({ variant: "outline" }))}`}
						>
							<IoMdShare />
							<Trans>Share</Trans>
						</DialogTrigger>
						<ShareRoomDialog roomNumber={roomState.room_number} />
					</Dialog>
				</div>

				<div className="my-3 md:ml-10 ml-5">
					<Badge variant="white" className="mb-2 flex pointer-none">
						<LiveIndicator state={channelSubscribeState} />
						<p className="ml-2">
							<Trans> Live </Trans>
						</p>
					</Badge>

					<Badge variant="outline" className="text-white mx-1">
						{userCount} {singularOrPluralUser}
					</Badge>
				</div>
			</div>
		</div>
	);
};
